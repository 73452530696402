.untag-material-modal-container {
    position: relative;
    top: 50%;
    left: 50%;
    width: 500px;
    padding: 20px 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 5px;
    transform: translate(-50%, -50%);
}

.untag-material-modal-actions-container {
    margin-top: 24px;
    text-align: right;
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.page-header {
    margin-bottom: 23px;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: #1d1d1d;
}

.page-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: #1d1d1d;
}

.tab-card-container {
    margin-top: 25px;
}

.tab-card-wrapper {
    width: 288px !important;
    height: 86px !important;
    margin: 0px !important;
    margin-right: 24px !important;
    padding: 0px !important;
    box-sizing: border-box !important;
}

.tab-card {
    width: 288px;
    height: 86px;
    padding: 16px 20px;
    color: #0055af;
    border: 1px solid #0055af;
    border-radius: 3px;
    cursor: pointer;
    box-sizing: border-box;

    &.active {
        color: #fff;
        background: #0056ae 0% 0% no-repeat padding-box;
    }

    &.disabled,
    &.disabled * {
        color: #acacac;
        border-color: #acacac;
        box-shadow: none;
    }
}

.tab-card-title {
    text-align: left;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
}

.tab-card-icon {
    margin-left: 8px;
    padding: 8px;
    color: #0055af;
    background-color: #fff;
    border: 1px solid #0055af;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
}

// Upload Price File Section

.upload-price-file-wrapper {
    margin-top: 44px;
}

.upload-price-file-container {
    padding: 22px 16px 35px;
    border: 1px solid #c8ced7;
    border-radius: 3px;
}

.upload-price-file-title {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
}

.upload-file-details-container {
    display: flex;
    width: calc(100% - 41px);
    margin-top: 17px;
    margin-bottom: -15px;
    padding: 24px 20px 26px;
    background-color: #f5faff;
}

.upload-file-icon {
    padding: 14px;
    color: #0054af;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;

    &.upload-file-icon-xls {
        height: 49px;
        width: 49px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.upload-file-details-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
}

.upload-file-details-title {
    color: #758490;
    font-size: 14px;
    line-height: 24px;
    padding-top: 16px;
}

.upload-file-name {
    font-weight: 500;
}

.upload-file-change {
    margin-top: 15px;
    color: #0055af;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.dropzone-wrapper {
    width: 100%;
    margin-top: 37px;
}

.dropzone-container {
    width: calc(100% - 38px);
    padding: 70px 0px 117px;
    background-color: #fafcff;
    border: 1px dashed #0054af;
    border-radius: 5px;
    box-sizing: border-box;
    color: #758490;
}

.upload-icon-container {
    width: 91px;
    height: 91px;
    color: #0054af;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
}

.browse,
.download-template {
    color: #0054af;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: #033162;
    }
}

.modal-upload-file-name {
    color: #666;
}

.modal-actions-container {
    margin-top: 24px;
    text-align: left;
}

.view-by-filtering-attributes {
    margin-top: 16px;

    .page-header {
        margin-bottom: 32px;
    }

    .page-title {
        color: #333;
        font-size: 18px;
        font-weight: bold;
    }
}

.select-plan-groups-container {
    border: 1px solid #c8ced7;
    border-radius: 3px;
}

@keyframes slide-left-to-right {
    from {
        right: -100%;
    }

    to {
        right: 0px;
    }
}

.sliding-overlay-container {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    box-sizing: border-box;
    z-index: 10;

    .content {
        position: relative;
        width: 800px;
        height: 100%;
        padding: 40px;
        background-color: #fff;
        box-sizing: border-box;
        animation-name: slide-left-to-right;
        animation-duration: 0.5s;
        animation-iteration-count: 1;

        .close-btn {
            color: #999;
        }

        .title {
            color: #666;
            font-size: 14px;
        }
    }
}

// Price Plan Chart

.price-plan-container {
    margin-top: 40px;
    padding: 16px;
    border: 1px solid #c8ced7;
    border-radius: 3px;

    .input-container {
        margin-right: 12px;
    }

    .input-label {
        margin-right: 10px;
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}

.price-plan-chart-section {
    margin-top: 24px;
    padding: 12px;
    overflow: auto;
}

.price-plan-chart-container {
    min-width: 420px;
    margin-right: 24px;
    padding: 20px;
    background-color: #f7f8fa;
    border: 1px solid #c8cdd7;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px #ddd;
}

.price-plan-chart-title {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
}

.price-plan-chart-legend-section {
    margin-top: 16px;
    font-size: 12px;
}

.price-plan-chart-legend {
    margin-right: 20px;
}

.price-plan-chart-legend-color {
    width: 10px;
    height: 10px;
    margin-right: 8px;
    border-radius: 50%;

    &:global.wholesale {
        background-color: #ff822a;
    }
    &:global.retail {
        background-color: #0054af;
    }
}

// Level Price Tables

.level-price-container {
    margin-top: 16px;
    padding: 18px 16px 24px;
    border: 1px solid #c8ced7;
    border-radius: 3px;

    & :global .MuiTabs-root {
        margin: 0px;
        border-bottom: 1px solid #acacac;
    }

    .input-container {
        // min-width: 300px;
        margin-right: 12px;
    }

    .input-label {
        margin-right: 10px;
        margin-bottom: 0px;
        font-size: 12px;
        font-weight: 400;
        line-height: 26px;
    }
}

.reset-modal-container,
.bulk-edit-modal-container {
    position: relative;
    top: 50%;
    left: 50%;
    width: 650px;
    padding: 20px 16px;
    background-color: #fff;
    color: #1d1d1d;
    box-sizing: border-box;
    border-radius: 5px;
    transform: translate(-50%, -50%);

    .sub-title {
        margin-top: 5px;
        font-size: 14px;
    }
}

.reset-modal-container {
    .warning-container {
        margin-top: 28px;
        padding: 10px 20px;
        font-size: 14px;
        background-color: #fff4ea;
        border: 1px solid #ff822a;
        border-radius: 3px;

        .icon {
            width: 20px;
            height: 20px;
            margin-right: 12px;
            color: #fff;
            border-radius: 50%;
            background-color: #ff822a;
        }
    }
}

.reset-modal-actions-container {
    text-align: right;
}

.bulk-edit-modal-container {
    gap: 20px;
    .date-section {
        margin-top: 12px;

        .title {
            font-size: 14px;
        }

        .date-picker {
            margin-top: 8px;
            width: 200px;
        }
    }
}

.multi-column-header {
    background-color: #e2e2e2;
}

.multi-column-tool-panel {
    border: none;
}

.dark-cell {
    background-color: #f7f7f7;
}

.audit-log-modal {
    background: white;
    box-sizing: border-box;
    padding: 1.5rem 2rem;
}

.audit-log-modal-header {
    font: normal normal 600 18px/27px Poppins, sans-serif;
    padding-bottom: 2rem;
}

.audit-log-modal-close-button {
    position: absolute;
    top: 0.5rem;
    right: 1.5rem;
    font-size: 2rem;
    cursor: pointer;
    color: #758490;
}

.audit-log-info-key {
    font: normal normal normal 16px/25px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #758490;
}

.audit-log-info-value {
    font-weight: 500;
    padding: 0.5rem;
    color: #1d1d1d;
}

.status-dot {
    border-radius: 50%;
    height: 0.75rem;
    width: 0.75rem;
    vertical-align: text-bottom;
    display: inline-block;
    margin-right: 0.25rem;
}

.cell-tooltip-container {
    // width: 1000px;
}

.cell-tooltip-text {
    max-width: 1000px;
    line-height: 20px;
}

.invalid-material-message {
    padding: 16px;
    background-color: #fce9ea;
    border: 1px solid #da1e28;
    display: flex;
    gap: 16px;
    width: fit-content;
    min-width: 30%;
    padding-right: 50px;
    text-align: left;
    margin-top: 16px;
}

.missing-gpm {
    background-color: #e5edf7;
    border-color: #0055af;
}

.download-invalid-materials {
    padding-top: 8px;
    display: flex;
    color: #0055af;
    width: fit-content;
    gap: 8px;
    font: normal normal 600 14px/24px Poppins, sans-serif;
    align-items: center;
    cursor: pointer;
}

.proceed-message {
    margin-top: 24px;
    font: normal normal medium 16px/26px Poppins, sans-serif;
    text-align: left;
    margin-bottom: 18px;
}

.button-link {
    border: none;
    background: white;
    font-size: inherit;
    text-decoration: none;
    font-family: inherit;

    &:hover {
        color: #033162;
    }
}

.load-indicator {
    margin-left: 6px;
    font-size: 24px;
    color: #758498;
}

.radio-group {
    margin-top: 10px;
    margin-bottom: 4px;
}

.page-sub-title {
    color: #1d1d1d;
    font-size: 16px;
    line-height: 27px;
    font-weight: 600;
}

.special-attribute-modal-container {
    position: relative;
    top: 50%;
    left: 50%;
    width: 800px;
    padding: 24px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 5px;
    transform: translate(-50%, -50%);

    &.sm {
        width: 400px;
    }

    & .close-btn {
        margin: -12px 0px;
        color: #333;
        font-size: 32px;
        border: none;
        background: none;
    }
}

.info-bulk-edit {
    padding-left: 10px;
    color: #0055af;
    vertical-align: middle;
}

.bulk-edit-date-container {
    border: 1px solid rgb(170, 170, 170);
    padding: 16px;
    border-radius: 8px;
}

.bulk-edit-modal-close-btn {
    position: absolute;
    right: 8px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
}

.bulk-edit-modal-title {
    position: relative;
    font-size: 20px;
    font-weight: 600;
}
