* {
    letter-spacing: 0 !important;
}

.text-align-right {
    text-align: right;
}

.no-wrap {
    white-space: nowrap;
}

.pointer {
    cursor: pointer;
}

.overflow-hidden {
    overflow: hidden !important;
}

.no-border {
    border: none;
}

.hidden {
    visibility: hidden;
}

.overflow-auto {
    overflow: auto;
}

.min-height-0 {
    min-height: 0px;
}

.no-drop {
    cursor: no-drop;
}

.text-ellipsis {
    text-overflow: ellipsis;
}

/* Gap */

.gap-12 {
    gap: 12px;
}

.gap-10 {
    gap: 10px;
}

/* Margin */

.mx-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-4 {
    margin-bottom: 4px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.my-16 {
    margin: 16px 0px;
}

.mt-16 {
    margin-top: 16px;
}

.m-16 {
    margin: 16px;
}

.my-20 {
    margin: 20px 0px;
}

/* Padding */
.p-8 {
    padding: 8px;
}

.pr-10 {
    padding-right: 10px;
}

.px-12 {
    padding-left: 12px;
    padding-right: 12px;
}

.p-16 {
    padding: 16px;
}

.p-20 {
    padding: 20px;
}

.p-28 {
    padding: 28px;
}

.p-24 {
    padding: 24px;
}

.pl-28 {
    padding-left: 28px !important;
}

/* Border */

.dotted-border {
    border: 2px dotted #acacac;
}

.solid-border {
    border: 1px solid #d4d4d4;
    padding: 10px;
}

/* Flex */

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-1 {
    flex: 1;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-wrap {
    flex-wrap: wrap;
}

.align-center,
.center-center,
.center-space-between {
    display: flex;
    align-items: center;
}

.align-top {
    align-items: flex-start;
}

.justify-center,
.center-center {
    display: flex;
    justify-content: center;
}
.justify-right {
    display: flex;
    justify-content: right;
}

.justify-space-between,
.center-space-between {
    display: flex;
    justify-content: space-between;
}

/* Flex end */

.App {
    text-align: center;
}

.button-primary {
    background-color: #4f677b;
    color: #fff;
}

.button-primary:hover {
    background-color: #4f677b;
}
.text-capitalise {
    text-transform: capitalize;
}
.grey-font {
    color: #8596a9;
}

.toggle-filters {
    margin-left: 6rem;
}

.d-none {
    display: none;
}

/* Firebase */

.firebaseui-idp-button {
    max-width: 180px !important;
}

.firebaseui-idp-button .firebaseui-idp-text {
    color: #475569;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
}

/* Firebase End */

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    /* border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 0px #44677b;
    color: #44677b;
    text-align: center;
    font-size: 0.65rem;
    padding: 2px 5px;
    position: absolute;
    z-index: 1;
    top: -90%;
    right: -50%;
    margin-left: -20px;
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #44677b transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.common-container {
    padding: 20px 25px 0 25px;
}

.common-container .stepper-steps .MuiStepper-root {
    padding: 20px 0;
}

.stepper-steps {
    width: 100% !important;
}

.stepper-container {
    display: flex;
    justify-content: space-between;
}

.common-container .step-content {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c8ced7;
    border-radius: 3px;
    text-align: left;
    padding: 16px 30px;
}

.common-container .step-content .MuiBox-root {
    margin: 0 auto;
}

.common-container .step-content .MuiGrid-root {
    width: 100%;
    margin-left: 0;
}

.common-container .step-content .accordion-content .MuiGrid-root.MuiGrid-item {
    padding: 0 30px 0px 0;
    max-width: 100%;
}

.common-container .step-content .accordion-content .MuiPaper-root {
    padding: 10px 0 0 0;
}

.common-container .step-content .accordion-content .MuiPaper-root.filterGroup {
    padding: 0 0 10px 0;
}

.common-container .step-content .MuiPaper-root.filterGroup label {
    display: block;
    padding-bottom: 5px;
    text-align: left;
    font: normal normal normal 12px/26px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #758490;
}

.common-container
    .step-content
    .MuiPaper-root.filterGroup
    label.required::after {
    content: "*";
    color: red;
}

.common-container .step-content .MuiAccordion-root::before {
    content: "";
    background-color: transparent;
}

.common-container .step-submit-buttons {
    gap: 20px;
    margin-top: 15px;
}

.common-container .step-submit-buttons button {
    margin: 0;
    width: 90px;
    height: 35px;
    border-radius: 3px;
}

.common-container .step-submit-buttons button.save-filters,
.common-container .step-submit-buttons button.clear-filters {
    width: 112px;
}

.common-container .field-label {
    display: block;
    text-align: left;
    font: normal normal normal 12px/26px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #758490;
}

.common-container .text-input .MuiInputBase-root {
    width: 300px;
    height: 35px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #acacac;
    border-radius: 3px;
    text-align: left;
    font: normal normal normal 14px/26px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #1d1d1d;
}

.common-container .text-input .MuiInputBase-root input {
    padding: 8px 20px;
}

.common-container .text-input fieldset {
    border: 0;
}

.common-container .input-select {
    width: 300px;
    height: 35px;
}

.common-container .accordion-content .filter-group-container .MuiGrid-item {
    max-width: 100%;
}

.common-container
    .accordion-content
    .filter-group-container
    .MuiGrid-item
    .dropdown-wrapper {
    width: 380px;
}

.grid-custom-content {
    display: flex;
    padding: 15px 0;
    justify-content: space-between;
    align-items: center;
}

.grid-custom-content .text-input-search .MuiInputBase-root {
    width: 346px;
    height: 35px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #acacac;
    border-radius: 3px;
    text-align: left;
    font: normal normal normal 14px/26px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #1d1d1d;
}

.grid-custom-content .text-input-search .MuiInputBase-root input {
    padding: 8px 20px;
}

.grid-custom-content .text-input-search fieldset {
    border: 0;
}

.table-extra-content-left {
    display: flex;
    gap: 20px;
}

.table-extra-content-left label.input-checkbox {
    margin: 0 !important;
    text-align: left;
    font: normal normal normal 14px/26px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #1d1d1d !important;
}

.table-extra-content-left .input-checkbox .MuiCheckbox-root {
    padding: 0;
}

.table-extra-content-left .input-checkbox .MuiCheckbox-root svg {
    width: 16px;
    height: 16px;
    padding-right: 4px;
}

.table-exra-content-right {
    height: 35px;
    width: 72px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #acacac;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.table-exra-content-right svg {
    width: 16px;
    height: 16px;
    fill: #0055af;
    flex: 1 1 auto;
    cursor: pointer;
}

.table-exra-content-right svg:nth-of-type(1) {
    border-right: 1px solid #acacac;
}

.filter-summary-container.table-selection-summary {
    margin: 0;
}

.MuiSnackbar-root {
    .MuiPaper-root {
        padding: 0px 12px !important;
    }
    .MuiSvgIcon-root {
        height: 18px;
        width: 18px;
        margin-left: 4px;
    }
}

.field-title {
    display: block;
    padding-bottom: 5px;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #758490;
    margin: 0;
}

.MuiFormControl-root.MuiTextField-root
    input.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 8px 16px;
}

.MuiFormControl-root.MuiTextField-root {
    width: 100%;
}

.MuiPickersLayout-root {
    display: flex !important;
    flex-direction: column !important;
}

.MuiButton-root:has(> .MuiSvgIcon-root) {
    min-width: 0;
    padding: 8.5px 7.5px;
}

.MuiButton-root {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.button-group-flex {
    display: flex;
    gap: 16px;
}

.MuiButton-text:hover {
    background-color: transparent !important;
    color: #033162;
}

.MuiButtonBase-root.MuiTab-root {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #758490;

    &.Mui-selected {
        font-weight: 600;
        color: #0055af;
    }
}

.MuiModal-root {
    word-wrap: break-word;
}

.hover-pointer:hover {
    cursor: pointer;
}

*:not(:hover) > .parent-hover-show {
    visibility: hidden;
}

.select-label {
    color: #758490;
    font-size: 12px;
    margin-bottom: 4px;
    display: block;
}

.MuiStepLabel-label {
    font-weight: 400 !important;
    &.Mui-active {
        font-weight: 600 !important;
    }
}

.MuiStepLabel-iconContainer {
    padding-right: 5px;
}

.MuiStepConnector-root.MuiStepConnector-horizontal {
    width: 26px;
    flex-grow: 0;
}

.MuiButtonBase-root.MuiStepButton-root {
    margin: 0;
    padding: 0;
}

.MuiStepLabel-horizontal > .MuiStepLabel-iconContainer {
    padding-right: 5px;
}

.MuiStep-root.MuiStep-horizontal {
    padding: 0px 5px;
}

.MuiStep-horizontal:first-child {
    padding-left: 0;
}

.MuiTextField-root > .MuiInputBase-root > textarea {
    padding: 2px 16px;
}

.breadcrumb-container .MuiTypography-root {
    text-decoration: none;
}

/* Responsive css starts here */
@media only screen and (max-width: 1510px) {
    .MuiStepLabel-iconContainer .MuiStepIcon-root {
        width: 32px;
        height: 32px;
    }

    .MuiStepLabel-labelContainer .MuiStepLabel-label {
        font-size: 12px;
    }

    .back_url {
        font-weight: 500;
        font-family: Poppins, sans-serif;
        font-size: 12px;
    }
    .edit_icon_stepper {
        width: 32px !important;
        height: 32px !important;
        box-sizing: border-box;
    }
}

.MuiGrid-root > .MuiGrid-item {
    padding-top: 8px !important;
}

.Mui-expanded.accordion-header {
    padding-bottom: 8px !important;
}

.MuiSwitch-thumb {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.radio-field-title {
    font-size: 14px;
    color: #1d1d1d;
    font-weight: 500;
}

.MuiChip-sizeSmall .MuiChip-label {
    padding-right: 10px;
    padding-left: 10px;
}

.MuiPaper-root.MuiAppBar-root {
    padding: 0px 20px;
}

.drawer-inner-full-height {
    position: fixed;
    text-align: left;
    top: 107px;
    right: 0;
    overflow: scroll;
    z-index: 11;
    background-color: white;

    /* height of header bar plus breadcrumb is 107px
    and width of sidebar is 72px, half of which is 36px */
    width: calc(45% - 36px);
    height: calc(100% - 107px);
}
.drawer-inner-full-height.sm {
    width: 600px;
    max-width: calc(100% - 72px);
}

.drawer-inner-full-height-cover {
    height: 100vh;
    z-index: 10;
    inset: 0px;
    position: fixed;
    background-color: rgb(29, 29, 29);
    opacity: 0.7;
}

.MuiPopover-paper.MuiPaper-elevation {
    box-shadow: 0px 0px 6px #00000029;
}

.menulistScroll {
    overscroll-behavior: contain;
}

.top_filter_main_container {
    padding: 15px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    margin-bottom: 15px;
    margin-top: 15px;
    padding-bottom: 5px;
}

.filter_top_filter_heading {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.chip_drag {
    color: #1d1d1d;
}

.hierarchy .chip_drag {
    background: rgba(255, 165, 2, 0.1);
    border: 1px solid #ffa502;
}
.hierarchy .chip_drag:hover {
    background: #ffa502;
    color: #1d1d1d;
}

.rows .chip_drag {
    background: rgba(83, 214, 154, 0.1);
    border: 1px solid rgb(83, 214, 154);
}

.rows .chip_drag:hover {
    background: rgb(83, 214, 154);
    color: #1d1d1d;
}

.metrics .chip_drag {
    background: rgba(0, 172, 203, 0.1);
    border: 1px solid #00accb;
}
.metrics .chip_drag:hover {
    background: #00accb;
    color: #1d1d1d;
}
.values .chip_drag {
    background: rgba(58, 133, 238, 0.1);
    border: 1px solid #3a85ee;
}
.values .chip_drag:hover {
    background: #3a85ee;
    color: #1d1d1d;
}
.dragabble-section-active .pivot-subtitle {
    font-weight: 700;
    color: #1d1d1d;
}

.dragabble-section-active .solid-border {
    border: 1px solid rgba(0, 85, 175, 0.5);
    box-shadow: 0px 0px 4px rgba(0, 85, 175, 0.5);
}

.dragabble-section-blocked {
    cursor: not-allowed;
    pointer-events: none;
}

.buyers-screen-container .ag-header-group-cell.ag-header-group-cell-with-group,
.buyers-screen-container .ag-header-group-cell.ag-header-group-cell-no-group {
    border-bottom: 1px solid var(--ag-border-color, #babfc7);
}

.buyers-screen-container
    .ag-pinned-left-header
    .ag-header-group-cell.ag-header-group-cell-no-group {
    border: none;
}

.buyers-screen-container .header-border-4 {
    border-right: 4px solid #e2e2e2 !important;
}
.buyers-screen-container .header-border-3 {
    border-right: 3px solid #e2e2e2 !important;
}
.buyers-screen-container .header-border-2 {
    border-right: 2px solid #e2e2e2 !important;
}
.buyers-screen-container .header-border-1 {
    border-right: 1px solid #e2e2e2 !important;
}

.buyers-screen-container
    .ag-theme-alpine
    .ag-ltr
    .ag-cell.ag-cell-focus.ag-cell-range-selected {
    border-right: 2px solid #0055af;
}

.planning-roll-topbar label {
    text-align: left;
}

.page-title {
    color: #1d1d1d;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
}

.empty-page-onload {
    display: flex;
    height: inherit;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    overflow: clip;
}

.empty-page-onload > div {
    display: flex;
    flex-direction: column;
}

.empty-page-onload b {
    color: #1d1d1d;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
}

.empty-page-onload img {
    width: 230px;
    height: 250px;
    margin-bottom: 10px;
}

.planning-roll-up-filter-accordian {
    border: 1px solid #c8ced7;
    margin-top: 15px;
    border-radius: 4px;
}
