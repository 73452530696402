.modal-container {
    background: white;
    box-sizing: border-box;
    padding: 1.5rem 2rem;
    position: relative;
    width: 70%;
}

.discount-body {
    // height: 490px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.title {
    font-size: 1.5rem;
    margin-top: 0;
}

.close-icon {
    position: absolute;
    top: 24px;
    right: 32px;
    cursor: pointer;
}

// Upload Price File Section

.upload-price-file-wrapper {
    margin-top: 44px;
}

.upload-price-file-container {
    padding: 22px 16px 35px;
    border: 1px solid #c8ced7;
    border-radius: 3px;
}

.upload-price-file-title {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
}

.upload-file-details-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 15px 0;
    background-color: #f5faff;
}

.upload-file-icon {
    padding: 14px;
    color: #0054af;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;
    margin-left: 5px;
    &.upload-file-icon-xls {
        height: 49px;
        width: 49px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.upload-file-details-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
}

.upload-file-details-title {
    color: #758490;
    font-size: 14px;
    line-height: 24px;
    padding-top: 16px;
}

.upload-file-name {
    font-weight: 500;
}

.upload-file-change {
    padding: 15px 0;
    color: #0055af;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.dropzone-wrapper {
    width: 100%;
    margin-top: 37px;
}

.dropzone-container {
    width: 100%;
    padding: 70px 0px 117px;
    background-color: #fafcff;
    border: 1px dashed #0054af;
    border-radius: 5px;
    box-sizing: border-box;
    color: #758490;
    text-align: center;
}

.upload-icon-container {
    width: 91px;
    height: 91px;
    color: #0054af;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
}

.browse,
.download-template {
    color: #0054af;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    border: none;
    background-color: #00008000;
    font-size: 16px;

    &:hover {
        color: #033162;
    }
}

.modal-upload-file-name {
    color: #666;
}

.modal-actions-container {
    display: flex;
}

.view-by-filtering-attributes {
    margin-top: 16px;

    .page-header {
        margin-bottom: 32px;
    }

    .page-title {
        color: #333;
        font-size: 18px;
        font-weight: bold;
    }
}

.select-plan-groups-container {
    border: 1px solid #c8ced7;
    border-radius: 3px;
}

@keyframes slide-left-to-right {
    from {
        right: -100%;
    }

    to {
        right: 0px;
    }
}

.sliding-overlay-container {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    box-sizing: border-box;
    z-index: 10;

    .content {
        position: relative;
        width: 800px;
        height: 100%;
        padding: 40px;
        background-color: #fff;
        box-sizing: border-box;
        animation-name: slide-left-to-right;
        animation-duration: 0.5s;
        animation-iteration-count: 1;

        .close-btn {
            color: #999;
        }

        .title {
            color: #666;
            font-size: 14px;
        }
    }
}

.invalid-material-message {
    padding: 16px;
    background-color: #fce9ea;
    border: 1px solid #da1e28;
    display: flex;
    gap: 16px;
    width: calc(100% - 30px);
    min-width: 30%;
    padding-right: 50px;
    text-align: left;
    margin-top: 16px;
}

.missing-gpm {
    background-color: #e5edf7;
    border-color: #0055af;
}

.download-invalid-materials {
    padding-top: 8px;
    display: flex;
    color: #0055af;
    width: fit-content;
    gap: 8px;
    font: normal normal 600 14px/24px Poppins, sans-serif;
    align-items: center;
    cursor: pointer;
}

.proceed-message {
    margin-top: 24px;
    font: normal normal medium 16px/26px Poppins, sans-serif;
    text-align: left;
    margin-bottom: 18px;
    display: flex;
    width: 100%;
    margin-left: -32px;
}

.button-group-flex {
    display: flex;
    width: 100%;
    margin-left: -35px;
}