@use "~ag-grid-community/styles" as ag;
@import "node_modules/ag-grid-community/styles";
@include ag.grid-styles(
    (
        theme: "alpine",
    )
);

.ag-theme-alpine {
    box-shadow: 0px 0px 6px #00000029;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;

    letter-spacing: 0.08px;
    color: #1d1d1d;
    --ag-odd-row-background-color: white;
    --ag-checkbox-checked-color: #0055af;
    --ag-alpine-active-color: var(--ag-checkbox-checked-color);
    --ag-input-focus-border-color: rgb(0, 85, 175, 0.4);
    --ag-range-selection-background-color: rgb(0, 85, 175, 0.2);
    --ag-range-selection-border-color: var(--ag-checkbox-checked-color);
    --ag-header-foreground-color: #1d1d1d;
    --ag-data-color: #1d1d1d;

    .ag-header-cell {
        line-height: 16px;
        font-weight: 500;
        text-transform: uppercase;
        height: 40px;
        text-align: left;

        &.ag-floating-filter {
            padding: 0px 12px;
        }
    }

    .ag-header {
        border-bottom-color: #758498;
        border-bottom-width: 1px;
    }
    .ag-header-row-column {
        & * {
            border-top: none;
        }
    }

    .ag-cell {
        border-right-width: 0;
        border-left-width: 0;

        &.ag-cell-focus,
        .ag-cell-range-selected {
            border-width: 0.667px;

            &:not(:has(> .ag-cell-wrapper > .ag-selection-checkbox)) {
                padding-left: 11.5px !important;
            }
        }

        &.ag-cell-range-left.ag-cell-range-selected {
            border-left-color: var(
                --ag-range-selection-border-color
            ) !important;
            border-left-width: 0.667px;
        }
        &.ag-cell-range-right.ag-cell-range-selected {
            border-right-color: var(
                --ag-range-selection-border-color
            ) !important;
            border-right-width: 0.667px;
        }
    }

    .ag-header-cell-resize {
        opacity: 0;
    }

    .ag-header-cell-label {
        white-space: normal;
    }

    .ag-pinned-left-cols-container {
        .ag-cell {
            background: #f7f7f7 0% 0% no-repeat padding-box;
        }
    }

    .ag-grid-header-overflow-visible {
        .ag-header-cell-comp-wrapper {
            overflow: visible;
        }
    }

    .ag-grid-hidden-row {
        display: none;
    }

    .ag-row-selected {
        .ag-cell {
            background-color: #f5faff;
        }
    }

    .ag-row-hover {
        .ag-cell {
            background-color: #f5faff;
        }
    }

    .ag-header-row {
        height: 40px !important;
        overflow: initial;
    }
    .ag-row {
        height: 45px;
        border-bottom-color: #e2e2e2;
        border-bottom-width: 1px;
    }

    .ag-header-cell:has(.ag-checkbox.ag-hidden),
    .ag-cell:not(:has(.ag-selection-checkbox)) {
        padding: 0px 12px;
    }

    .ag-root-wrapper {
        border: none;
    }

    .ag-header-row-column div {
        border-top: none !important;
    }

    .ag-cell {
        overflow-x: clip;
        vertical-align: middle;
        text-align: left;
        height: inherit;
        .MuiFormControl-root:has(> .MuiInputBase-root) {
            height: 100%;

            .MuiInputBase-root {
                height: 100%;
            }
        }

        .MuiOutlinedInput-root {
            background-color: white;
        }

        .MuiButton-root {
            display: flex;
            font-size: 14px;
        }

        .ag-cell-wrapper {
            .ag-cell-value {
                display: flex;
                align-items: center;
            }

            .ag-cell-value:has(input) {
                input:not(:disabled) {
                    background-color: white;
                }
            }
        }
    }

    .left-sticky-label .ag-header-group-cell-label {
        left: 12px;
        position: sticky;
        flex: none;
        max-width: 100%;
        justify-content: left !important;
    }

    .ag-checkbox-input-wrapper {
        box-shadow: none;

        &:not(.ag-checked)::after {
            color: #acacac;
        }
    }
    .ag-checkbox-input {
        height: 15px;
    }
}

.custom-ag-grid-header-menu-button:hover {
    color: #2196f3;
}

.custom-ag-grid-header-menu-button {
    visibility: hidden;
}

.ag-header-cell:hover .custom-ag-grid-header-menu-button {
    visibility: visible;
}

.ag-disabled-cell {
    background: #f7f7f7 0% 0% no-repeat padding-box;
    border-right: 0px;
    border-left: 0px;
}

.justify-center {
    .ag-header-cell-label {
        display: flex;
        justify-content: center;
    }
}

.extra-border {
    border-right-width: 1px !important;
    border-right-style: solid;
    border-right-color: var(--ag-border-color, #babfc7) !important;
    &.l-border {
        border-right-width: 2px !important;
    }
    &.xl-border {
        border-right-width: 3px !important;
    }
    &.xxl-border {
        border-right-width: 4px !important;
    }
    &.xxxl-border {
        border-right-width: 5px !important;
    }
    &.xxxxl-border {
        border-right-width: 6px !important;
    }
}

.no-bottom-border {
    border-bottom: none !important;
}

.no-top-border {
    border-top: none !important;
}

.header-pink {
    background-color: #c6bdd4;
}
.sub-header-pink {
    background-color: #d3cddc;
}
.sub-sub-header-pink {
    background-color: #e3dfea;
}
.header-blue {
    background-color: #abc2e0;
}
.sub-header-blue {
    background-color: #d2deec;
}
.sub-sub-header-blue {
    background-color: #e4edf7;
}
.header-gray {
    background-color: #d1d3e0;
}
.sub-header-gray {
    background-color: #dfe1ea;
}
.sub-sub-header-gray {
    background-color: #ebecf0;
}

.MuiPopover-paper {
    max-height: 150px;
}

.finalize-cell-edit-container {
    display: flex;
    gap: 15px;
}

.finalize-offer-type-container,
.finalize-offer-value-container {
    display: flex;
    align-items: center;
}

.finalize-offer-type-container .MuiInputBase-root,
.finalize-offer-value-container .MuiInputBase-root {
    width: 95px;
    height: 35px;
}

.event-name-container {
    display: flex;
}

.event-name-container .MuiSvgIcon-root {
    margin: auto 2px auto auto;
    height: 16px;
    width: 16px;
    cursor: pointer;
}

.promo-name-container {
    display: flex;
    align-items: center;
}

.promo-name-container .MuiSvgIcon-root {
    margin: auto 2px auto auto;
    height: 16px;
    width: 16px;
    cursor: pointer;
}

.promo-status {
    border: 1px solid #758498;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font: normal normal normal 10px/16px Poppins, sans-serif;
    letter-spacing: 0.06px;
    color: #758498;
}

.show-cell {
    background: white;
    border-left: 1px solid lightgrey !important;
    border-right: 1px solid lightgrey !important;
    border-bottom: 1px solid lightgrey !important;
}

.ag-header-group-cell-label {
    text-align: center;
    letter-spacing: 0.08px;
    color: #1d1d1d;
    justify-content: center !important;
}

.performanceCell .MuiChip-root.toxic {
    background: #fce9ea 0% 0% no-repeat padding-box;
    border: 1px solid #da1e28;
    border-radius: 15px;
}

.performanceCell .MuiChip-root.good {
    background: #e8f5ec 0% 0% no-repeat padding-box;
    border: 1px solid #24a148;
    border-radius: 15px;
}

.performanceCell .MuiChip-root.average {
    background: #fff3ea 0% 0% no-repeat padding-box;
    border: 1px solid #ff832b;
    border-radius: 15px;
}

.text-editable-table-cell {
    display: flex;
    align-items: center;
}

.text-editable-table-cell .offer-indicator {
    background: #f4f9ff 0% 0% no-repeat padding-box;
    color: #225791;
    border: 1px solid #225791;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font: normal normal normal 10px/16px Poppins, sans-serif;
    letter-spacing: 0.06px;
}

.text-editable-table-cell .offer-name {
    font-weight: 600;
    color: #0055af;
}

.event-name-container {
    display: flex;
    align-items: center;
}

.event-name-container .event-status {
    background: #f4f9ff 0% 0% no-repeat padding-box;
    color: #225791;
    border: 1px solid #225791;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font: normal normal normal 10px/16px Poppins, sans-serif;
    letter-spacing: 0.06px;
}

.event-name-container .event-name {
    font-weight: 600;
    color: #0055af;
}

.grid-custom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.grid-custom-content-left {
    display: flex;
}

.grid-custom-content-right {
    display: flex;
    gap: 20px;
    align-items: center;
}

.metric-selection {
    display: flex;
    align-items: center;
    gap: 10px;
}

.metric-selection label {
    text-align: right;
    font: normal normal normal 12px/26px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #758490;
}

.grid-custom-content-right .MuiSvgIcon-root.event-edit,
.grid-custom-content-right .MuiSvgIcon-root.event-delete {
    color: #fff;
    background: #0055af;
    padding: 8px;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    cursor: pointer;
}

.grid-custom-content-right button {
    margin: 0;
}

.table-extra-content-left {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
}

.text-editable-table-cell .text-input {
    margin-top: 5px;
}

.common-container .text-editable-table-cell .text-input .MuiInputBase-root {
    width: 200px !important;
}

.common-container
    .text-editable-table-cell
    .text-input
    .MuiInputBase-root
    input {
    padding-left: 2px;
    text-overflow: ellipsis;
}

.ag-grid-column-group-header {
    background: #00000029 0% 0% no-repeat padding-box !important;
}

.ag-grid-column-step6 {
    background: #fbbe4cdb !important;
}

.ag-row {
    z-index: 0;
    &.ag-row-focus {
        z-index: 1;
    }
}

.ag-grid-column-group-header-centered {
    justify-content: center;
}

.ag-highlighted-row {
    .ag-cell {
        color: #fff !important;
        background: #0055ae !important;
        opacity: 0.7 !important;
    }
}

.ag-checkbox-input:disabled {
    opacity: 1 !important;

    &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 14px;
        height: 14px;
        background-color: rgb(239, 239, 239);
        transform: translate(-50%, -50%);
    }
}

.roll-up-sale-mode-header {
    text-transform: inherit !important;
    font-weight: 700 !important;
    padding: 0 18px !important;
}